import React, { useRef } from "react"
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Header from "./Header";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import Footer from "./Footer";
import Hero from "./Hero";
import SubsriptionBox from "./SubscriptionBox";
import Modal from "./Modal";
import MailSubscribe from "./MailSubscribe";

const Layout = ({ location, title, type, heroTitle, children, hideSecTitle }) => {
  const modalRef1 = useRef();

  let myTheme = createTheme({
    palette: {
      type: 'light',
      primary: {
        main: '#EA4253',
        contrastText: "#fff"
      },
      secondary: {
        main: '#f50057',
        text: "#fff"
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1440,
      },
    },
  });
  myTheme = responsiveFontSizes(myTheme);

  return (
    <ThemeProvider theme={myTheme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          backgroundColor: '#fff'
        }}
      >
        <CssBaseline />
        <Modal ref={modalRef1}>
          <MailSubscribe />
        </Modal>
        <Header />
        <Hero type={type} title={title} displayTitle={heroTitle} />
        <Container maxWidth="lg" id="projects">
          <Box pt={4} mb={2} >
            {hideSecTitle !== true  &&
              <Typography variant="h4" component="h1" gutterBottom mb={4} >
                {title}
              </Typography>
            }
            <main>{children}</main>
          </Box>
        </Container>
        <SubsriptionBox />
        <Footer />
      </Box>
    </ThemeProvider>

  )
}

export default Layout
