import React, { Component } from "react"
import { Index } from "elasticlunr"
import { Link } from "gatsby"
import SearchIcon from '@mui/icons-material/Search';
import "./search.scss"

// Search component
export default class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {
      query: ``,
      results: [],
    }
  };

  getOrCreateIndex = () =>
    this.index
      ? this.index
      : // Create an elastic lunr index and hydrate with graphql query results
      Index.load(this.props.searchIndex)

  search = evt => {
    const query = evt.target.value
    this.index = this.getOrCreateIndex()
    this.setState({
      query,
      // Query the index with search string to get an [] of IDs
      results: this.index
        .search(query, { expand: true }) // Accept partial matches
        // Map over each ID and return the full document
        .map(({ ref }) => this.index.documentStore.getDoc(ref)),
    })
  }

  render() {

    return (
      <div className="container">
        <div className="search-box">
          <div className="search-box__input">
            <input type="text" id="wordInput" placeholder="Buscar palabra" className="search-input" value={this.state.query} onChange={this.search}/>
          </div>
          <div className="search-box__button">
              <SearchIcon fontSize="large" />
          </div>
        </div>
        <div className="result-container">
          <ul className='result-list'>
            {this.state.results.map(page => (
              <li key={page.id} className="list-item">
                <Link to={"/" + page.path} >{page.title}</Link>
              </li>
            ))}
          </ul>
        </div>
      </div>

    )
  }
}