import React, { useState } from "react"
import "./mail-subscribe.scss"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { Link, navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

function MailSubscribe() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const tags = "2312534,2312718";

  let handleSubmit = async e => {
    e.preventDefault()

    addToMailchimp(email, { FNAME: name, tags: tags })
      .then(({ msg, result }) => {
        console.log("msg", `${result}: ${msg}`)

        if (result !== "success") {
          throw msg
        }

        localStorage.setItem("subscribed", true)
        sessionStorage.setItem("displayed", true)

        navigate("/subscription-success/")
      })
      .catch(err => {
        console.log("err", err)
        alert(err)
      })
  }

  return (
    <div className="mail-subscribe">
      <div className="mail-subscribe__content">
        <div className="img-container">
          <StaticImage
            src="../../images/Logo-7Sabores-Horizontal.png"
            alt="7sabores logo"
            placeholder="none"
          ></StaticImage>
        </div>
      </div>
      <div className="mail-subscribe__form">
        <form
          className="mail-subscribe-form"
          name={`mail-subscribe`}
          method="POST"
          onSubmit={handleSubmit}
        >
          <h2>Are you ready to work remotely with U.S companies?</h2>
          <div className="text">
            Find out with our developer's Ebook if you have what it takes to
            earn in dollars and grow professionally.
          </div>

          <div className="subscribe-calendar">
            <StaticImage
              src="../../images/Icono_calendario-03.png"
              alt="Calendar"
              placeholder="blurred"
            />
            Subscribe now and receive it along with tips and job offers from
            weKnow.
          </div>
          <div className="subscribe">
            <div className="subscribe-field">
              <input
                className="subscribe-input"
                type="text"
                label="Name"
                onChange={e => setName(e.target.value)}
                placeholder="Name"
                name="name"
                id="name"
              />
            </div>
            <div className="subscribe-field">
              <input
                className="subscribe-input"
                type="email"
                id="email"
                label="Email"
                placeholder="Email address"
                required
                onChange={e => setEmail(e.target.value)}
              />
            </div>

            <button type="submit" className="subscribeB">
              Subscribe
            </button>
          </div>

          <label className="checkbox-container">
            <input id="acceptsubscribe" type="checkbox" required="required" />
            <p>
              I would like to subscribe to the weKnow newsletter. See{" "}
              <Link to="/privacy">privacy policy</Link>.
            </p>
            <span className="checkmark"></span>
          </label>
        </form>
      </div>
    </div>
  )
}

MailSubscribe.propTypes = {}

export default MailSubscribe
