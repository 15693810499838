import React, { useState } from "react"
import "./subscription-input.css"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { navigate } from "gatsby"

export default function SubscriptionInput() {
  const [email, setEmail] = useState("")
  const tags = "2312534,2312718";
  let handleSubmit = async e => {
    e.preventDefault()

      addToMailchimp(email, { tags: tags })
        .then(({ msg, result }) => {
          console.log('msg', `${result}: ${msg}`)

          if (result !== 'success') {
            throw msg
          }

          localStorage.setItem('subscribed', true);
          sessionStorage.setItem('displayed', true);

          navigate(
            "/subscription-success/"
          )

        })
      .catch(err => {
        console.log("err", err)
        alert(err)
      })
  }

  return (
    <form
      method="POST"
        onSubmit={handleSubmit}
    >
      <div className="subscribe" id="subscribeInput">
        <div className="t">
          <input
            className="subscribe-input"
            type="email"
            id="email"
            label="Email"
            placeholder="Tu Email"
            required
            onChange={e => setEmail(e.target.value)}
          />
        </div>
        <button
          type="submit"
          className="subscribeB"
          style={{
            background: "linear-gradient(to right top, #FD7786, #EA4253)",
          }}
        >
          Subscribe
        </button>
      </div>
    </form>
  )
}
