import * as React from 'react';
import { Box } from '@mui/system';
import { IconButton } from 'gatsby-theme-material-ui';
import TwitterIcon from '@mui/icons-material/Twitter';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LanguageIcon from '@mui/icons-material/Language';
import SvgIcon from '@mui/material/SvgIcon';

function DrupalIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="m15.67188,4.89453c-1.65235,-1.61719 -3.22657,-3.15625 -3.69532,-4.89453c-0.46875,1.73828 -2.04297,3.27734 -3.69531,4.89453c-2.47266,2.42188 -5.28125,5.16797 -5.28125,9.28516c0,4.85547 4.01953,8.79297 8.97656,8.79297c4.96094,0 8.98047,-3.9375 8.97657,-8.79297c0,-4.11719 -2.80469,-6.86328 -5.28125,-9.28516m-8.85547,11.33594c-0.55078,-0.01953 -2.58203,-3.44922 1.1875,-7.10156l2.49218,2.66797c0.04297,0.04296 0.06641,0.10156 0.0625,0.16015c-0.0039,0.0586 -0.03125,0.11719 -0.07812,0.15235c-0.59766,0.59765 -3.13281,3.08984 -3.44922,3.94921c-0.0625,0.17969 -0.16016,0.17188 -0.21484,0.17188m5.16015,4.51562c-1.70312,0 -3.08593,-1.35156 -3.08593,-3.02343c0.01171,-0.73047 0.28125,-1.4375 0.76953,-1.9961c0.55468,-0.66406 2.3164,-2.53906 2.3164,-2.53906c0,0 1.73438,1.90234 2.3125,2.53516c0.50782,0.54687 0.78125,1.26172 0.77735,2c0,1.67187 -1.38282,3.02343 -3.08985,3.02343m5.91016,-4.90234c-0.06641,0.14453 -0.21875,0.38281 -0.42188,0.39063c-0.36328,0.01171 -0.40234,-0.17188 -0.66796,-0.5586c-0.58985,-0.85547 -5.73438,-6.11719 -6.69532,-7.13672c-0.84765,-0.89453 -0.12109,-1.52734 0.21875,-1.85937c0.42188,-0.41406 1.65625,-1.6211 1.65625,-1.6211c0,0 3.6836,3.42188 5.21485,5.75782c1.53515,2.33984 1.00781,4.35937 0.69531,5.02734" />
      </SvgIcon>
    );
  }

function FacebookIcon(props){
    return(
        <SvgIcon {...props}>
            <path d="M 17.0327 13.4146 l 0.613 -3.9942 h -3.8326 v -2.592 c 0 -1.0927 0.5354 -2.1579 2.2519 -2.1579 h 1.7424 V 1.2698 S 16.2262 1 14.7144 1 c -3.1562 0 -5.2193 1.9131 -5.2193 5.3762 v 3.0442 H 5.9867 V 13.4146 h 3.5084 v 9.6558 h 4.318 V 13.4146 z"/>
        </SvgIcon>
    )
}

function LinkedInIcon (props){
    return(
        <SvgIcon {...props}>
            <path d="M 6.7318 20.496 H 2.8308 V 7.9338 h 3.901 z M 4.7792 6.2202 C 3.5318 6.2202 2.52 5.187 2.52 3.9396 a 2.2592 2.2592 90 0 1 4.5184 0 c 0 1.2474 -1.0122 2.2806 -2.2592 2.2806 z M 21.3318 20.496 h -3.8926 V 14.3808 c 0 -1.4574 -0.0294 -3.3264 -2.0282 -3.3264 c -2.0282 0 -2.339 1.5834 -2.339 3.2214 V 20.496 h -3.8968 V 7.9338 h 3.7414 v 1.7136 h 0.0546 c 0.5208 -0.987 1.793 -2.0286 3.691 -2.0286 c 3.948 0 4.6738 2.5998 4.6738 5.9766 V 20.496 z"/>
        </SvgIcon>
    )
}
  

const IconContainer = ({type, href}) => {

        return (
            <Box sx={{
                backgroundColor: "#CECECE",
                height: "30px",
                width: "30px",
                borderRadius: "5px",
                display:"flex",
                justifyContent:"center",
                alignItems:"center"
            }}>
                <IconButton aria-label={type} size="small" sx={{ color: "#FFFFFF" }} href={href}>
                    {type === "Facebook" && <FacebookIcon />}
                    {type === "Twitter" && <TwitterIcon />}
                    {type === "LinkedIn" && <LinkedInIcon /> }
                    {type === "Email" && <MailOutlineIcon/>}
                    {type === "Website" && <LanguageIcon />}
                    {type === "Drupal" && <DrupalIcon /> }
                </IconButton>
            </Box>
        )
}

export default IconContainer

