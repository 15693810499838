import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Chip from "@mui/material/Chip"

const GlossaryPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark

  return (
    <Layout location={location} title={post.frontmatter.title} type="glosario">
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          {post.frontmatter.type &&
            <Chip
              key={post.frontmatter.type}
              label={post.frontmatter.type}
            />
          }
        </header>
        <section
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
        <hr />
        <footer>
        </footer>
      </article>

    </Layout>
  )
}

export default GlossaryPostTemplate

export const pageQuery = graphql`
  query GlossaryBySlug(
    $id: String!
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt
      html
      frontmatter {
        title
        type
      }
    }
  }
`
