exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acerca-7-saborescom-js": () => import("./../../../src/pages/acerca-7saborescom.js" /* webpackChunkName: "component---src-pages-acerca-7-saborescom-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mobile-subscribe-js": () => import("./../../../src/pages/mobile-subscribe.js" /* webpackChunkName: "component---src-pages-mobile-subscribe-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-subscription-success-js": () => import("./../../../src/pages/subscription-success.js" /* webpackChunkName: "component---src-pages-subscription-success-js" */),
  "component---src-templates-blog-pages-js": () => import("./../../../src/templates/blog-pages.js" /* webpackChunkName: "component---src-templates-blog-pages-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-contribuidores-js": () => import("./../../../src/templates/contribuidores.js" /* webpackChunkName: "component---src-templates-contribuidores-js" */),
  "component---src-templates-glosario-js": () => import("./../../../src/templates/glosario.js" /* webpackChunkName: "component---src-templates-glosario-js" */),
  "component---src-templates-glossary-post-js": () => import("./../../../src/templates/glossary-post.js" /* webpackChunkName: "component---src-templates-glossary-post-js" */)
}

