import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Glossaryitem from "../components/GlossaryItem"
import { Grid } from "@mui/material"
import PaginationNew from "../components/PaginationNew"
import Box from "@mui/material/Box"
import SearchBox from "../components/SearchBox"

const glossaryIndex = ({ data, location, pageContext }) => {
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title="Glosario" type="glosario" >
        <Seo title="Glosario" />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title="Glosario" type="glosario" hideSecTitle={true}>
      <Seo title="Glosario" />
      <SearchBox />
      <Grid
        container
        spacing={4}
        sx={{ mb:3, mt:0.4 }}
      >
        {posts.map(post => {

          const title = post.frontmatter.title || post.fields.slug
          const path = post.frontmatter.path
          const definition = post.html
          let strippedDefinition = definition.replace(/(<([^>]+)>)/gi, "");

          return (
            <Grid item xs={12} sm={6} key={path}>
              <Glossaryitem title={title} path={path} definition={strippedDefinition} />
            </Grid>
          )
        })}
      </Grid>

      <Box my={6} display="flex" justifyContent="center">
        <PaginationNew
          count={pageContext.numPages}
          defaultPage={pageContext.currentPage}
          pagePath="glosario"
        />
      </Box>
    </Layout>
  )
}

export default glossaryIndex

export const glossaryListQuery = graphql`
  query glossaryListQuery($skip: Int!, $limit: Int!){
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: {order: ASC, fields: [fields___slug]}
      filter: {fileAbsolutePath: {regex: "\/glosario/"}}
      limit: $limit
      skip: $skip
    ) {
      nodes {
        html
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          summary
          path
        }
      }
    }
  }
`
