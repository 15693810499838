import React from "react";
import Seo from "../components/Seo";
import { Box, Container, Typography } from '@mui/material';
import Header from "../components/Header";
import {
    createTheme,
    ThemeProvider,
    responsiveFontSizes,
} from "@mui/material/styles";
import Footer from "../components/Footer";
import { StaticImage } from "gatsby-plugin-image"




const ApplyResponse = () => {

    let myTheme = createTheme({
        palette: {
            type: 'light',
            primary: {
                main: '#EA4253',
                contrastText: "#fff"
            },
            secondary: {
                main: '#f50057',
                text: "#fff"
            },
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1440,
            },
        },
    });
    myTheme = responsiveFontSizes(myTheme);

    return (
        <div>
            <Seo title="Thank you for subscribing!" />
            <ThemeProvider theme={myTheme}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100vh',
                        backgroundColor: '#fff'
                    }}
                >
                    <Header />
                    <Container maxWidth="lg" sx={{ mt: 6, display: "flex", flexDirection: "column", alignItems: "center" }}  >
                        <Typography variant="h3" fontFamily="Antartida" fontWeight="bold" sx={{ mb: 3, textAlign: "center" }}>All set!</Typography>
                        <Typography variant="h5" fontFamily="Antartida" sx={{ mb: 3, textAlign: "center" }}>You have succssfully subscribed to our list. You will receive the ebook soon.</Typography>
                        <Typography variant="h5" fontFamily="Antartida" sx={{ mb: 3, textAlign: "center" }}>Please email us at yes@weknowinc.com if you have any questions.</Typography>
                        <Box sx={{ width: "25vw", minWidth: "15vw", mb: 3 }}>
                            <StaticImage src="../images/Logo-7Sabores-Vertical-Positivo.png" />
                        </Box>
                    </Container>
                    <Footer />
                </Box>

            </ThemeProvider>
        </div>
    );
};

export default ApplyResponse;
