import * as React from "react"
import { graphql } from "gatsby"
import Link from "../components/Link"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Typography from '@mui/material/Typography';
import { makeStyles } from "@mui/styles"
import PostInfo from "../components/PostInfo"
import "../css/blog-post.css"

const useStyles = makeStyles((theme) => {
 // const tags = ["h1", "h2", "h3", "h4", "h5", "h6"];

  const tags = [
    {
      "tag":"h1",
      "variant": "h3",
    },
    {
      "tag": "h2",
      "variant": "h4",
    },
    {
      "tag": "h3",
      "variant": "h5",
    },
    {
      "tag": "h4",
      "variant": "h6",
    },
    {
      "tag": "h5",
      "variant": "h6",
    },
    {
      "tag": "h6",
      "variant": "h6",
    },
  ];

  const nestedRules = {};
  tags.forEach((tag) => {
    nestedRules[`& ${tag.tag}`] = { ...theme.typography[tag.variant] };
  });
  return {
    root: nestedRules
  };
});

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = post.frontmatter.title || `Title`
  const { previous, next } = data
  const date = post.frontmatter.date.charAt(0).toUpperCase() + post.frontmatter.date.slice(1)
  const author = post.frontmatter.author
  const topics = post.frontmatter.topics


  const classes = useStyles();

  return (
    <Layout location={location} title={siteTitle} type="blog" heroTitle={true} hideSecTitle={true}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <PostInfo
          name={author}
          date={date}
          tags={topics}
        />
        <section>
          <Typography mt={3}
            className={classes.root}
            variant="body1"
            itemProp="articleBody"
              p={3}
              sx={{fontFamily:"Helvetica"}}
          >
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
          </Typography>
        </section>
      </article>
      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {next && (
              <Link to={`/${next.frontmatter.path}`} rel="prev">
                ← {next.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {previous && (
              <Link to={`/${previous.frontmatter.path}`} rel="next">
                {previous.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY", locale: "es-MX")
        author
        topics
        description
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
        path
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
        path
      }
    }
  }
`
