import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from 'gatsby-theme-material-ui';
import CardActions from '@mui/material/CardActions';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { Box } from '@mui/material';
import IconContainer from './IconContainer';

export const ContributorCard = ({ card }) => {
  const name = card.frontmatter.name || card.fields.slug
  const title = card.frontmatter.title
  const created = card.frontmatter.created
  const image = getImage(card.frontmatter.picture)
  const facebook = card.frontmatter.facebook
  const twitter = card.frontmatter.twitter
  const linkedin = card.frontmatter.linkedin
  const email = card.frontmatter.email
  const website = card.frontmatter.website
  const drupal = card.frontmatter.drupal

  let coverImage
  if (image !== undefined) {
    coverImage = < GatsbyImage image={image} alt={name} />
  } else {
    coverImage = <StaticImage
      src="../images/user.png"
      alt={name}
      placeholder="blurred"
      width={600}
      height={600}
    />
  }

  let location
  let regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

  if (card.frontmatter.province) {
    location = card.frontmatter.province + ', ' + regionNames.of(card.frontmatter.country)
  } else {
    location = regionNames.of(card.frontmatter.country)
  }

  const company = (
    < Link to={card.frontmatter.company_url} itemProp="url" underline='none' sx={{ color: "#8B8B8B" }}>
      <span>{card.frontmatter.company}</span>
    </Link>
  )

  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }} >
      <CardMedia>
        {coverImage}
      </CardMedia>

      <CardContent sx={{ flexGrow: 1, p: 0.5 }}>
        <Box sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          mt: 1,
          mr: 2,
        }}>
          <DateRangeIcon fontSize='small' sx={{ color: '#EA4253', mr: 1 }} />
          <Typography fontFamily="Montserrat" fontWeight="light" textTransform="uppercase" sx={{ color: "#676767", fontSize: "11px", pt:0.3 }}>
            {created}
          </Typography>
        </Box>
        <Box sx={{
          display: "flex",
          flexDirection: "row",
          mt: 2,
          ml: 3.5
        }}>
          <PersonOutlineIcon fontSize='large' sx={{ color: '#EA4253' }} />
          <Typography fontFamily="Antartida" fontWeight='Bold' sx={{ fontSize: "24px", ml: 2, pt: 0.5, mr: 2 }}>
            {name}
          </Typography>
        </Box>
        <Box sx={{
          ml: 10
        }}>
          <Typography fontFamily="Antartida" fontWeight="light" sx={{ color: "#333333", fontSize: "14px" }}>
            {title}
          </Typography>
          <Typography fontFamily="Antartida" fontWeight="light" sx={{ color: "#8B8B8B", fontSize: "12px" }} >
            {company}
          </Typography>
          <Typography fontFamily="Antartida" sx={{ color: "#EA4253", fontSize: "12px", mt: 0.5 }}>
            {location}
          </Typography>
        </Box>
      </CardContent>

      <CardActions sx={{
        display: "flex",
        justifyContent: "center",
        p: 0.5,
        mb: 1,
        mt:2
      }}>
        {facebook &&
          <IconContainer type="Facebook" href={facebook}/>
        }
        {twitter &&
          <IconContainer type="Twitter" href={twitter}/>
        }
        {linkedin &&
          <IconContainer type="LinkedIn" href={linkedin}/>
        }
        {email &&
          <IconContainer type="Email" href={ 'mailto:' + email}/>
        }
        {website &&
          <IconContainer type="Website" href={website} />
        }
        {drupal &&
          <IconContainer type="Drupal" href={drupal}/>
        }

      </CardActions>
    </Card>
  )
}

export default ContributorCard
