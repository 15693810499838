import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import BlogCardNew from "../components/BlogCard/index"
import PaginationNew from "../components/PaginationNew"
import Box from "@mui/material/Box"
import { Divider } from "@mui/material"

const blogList = ({ data, location, pageContext }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="Entradas de Blog" />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (

    <Layout location={location} title="Entradas de Blog" type="blog" hideSecTitle={true}>
      <Seo title="Entradas de Blog" />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 1,
          m: 1
        }}>
        <Divider />
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug

          const postContent = {
            title: title,
            description: post.excerpt,
            image: post.frontmatter.cover,
            imageText: title,
            link: post.frontmatter.path,
            linkText: 'Ver',
            topics: post.frontmatter.topics,
            author: post.frontmatter.author,
            date: post.frontmatter.date.charAt(0).toUpperCase() + post.frontmatter.date.slice(1)
          };

          return (

            <BlogCardNew key={post.frontmatter.path} post={postContent} />
          )
        })}
      </Box>

      <Box my={6} display="flex" justifyContent="center">
        <PaginationNew
          count={pageContext.numPages}
          defaultPage={pageContext.currentPage}
          pagePath="blog"
        />
      </Box>

    </Layout>
  )
}

export default blogList

export const blogListQuery = graphql`
query blogListQuery($skip: Int!, $limit: Int!) {
  site {
    siteMetadata {
      title
    }
  }
  allMarkdownRemark(
    sort: { fields: [frontmatter___date], order: DESC }
    filter: {fileAbsolutePath: {regex: "/blog/"}}
    limit: $limit
    skip: $skip
    ) {
    nodes {
      excerpt
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY", locale: "es-MX")
        title
        author
        topics
        summary
        path
        cover {
          childImageSharp {
            gatsbyImageData(
              width: 476
              height: 304
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  }
}
`
