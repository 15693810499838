import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react"
import Portal from "../Portal"
import "./modal.scss"


const Modal = forwardRef((props, ref) => {

  const [display, setDisplay] = useState(false)


  useImperativeHandle(
    ref,
    () => {
      return {
        openModal: () => handleOpen(),
        closeModal: () => handleClose(),
      }
    }
  )

  useEffect(() => {

    let displayed = sessionStorage.getItem('displayed');
    let subscribed = localStorage.getItem('subscribed');

    if (!displayed && !subscribed) {  
        setDisplay(true);
    }
  }, []);

  const handleOpen = () => {
    setDisplay(true);
  }

  const handleClose = () => {
    sessionStorage.setItem('displayed', true);
    setDisplay(false);
  }

  if (display && false) {
    return (
      <Portal>
        <div className="modal-backdrop"></div>
        <div className="modal-container">
          <button onClick={handleClose} className="close">X</button>
          {props.children}
        </div>
      </Portal>
    )
  }

  return null
})

export default Modal
