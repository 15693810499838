
import * as React from "react"
import './glossary-item.css'


export default function Glossatyitem({ title, path, definition }) {

    return (
        <div className="glossary-conatiner">
            <a href={`/${path}`} className="glossary-link">
                <p className="glossary-definition">
                    <span className="glossary-term">
                        {title}:
                    </span>
                    {definition}
                </p>
            </a>
        </div>
    )
}
