import React from "react"
import { Box, Typography } from "@mui/material"
import SubscriptionInput from "./SubscriptionInput"

export default function SubsriptionBox() {

  return (
    <Box
      sx={{
        backgroundColor: "#383941",
        display: { xs: "none", sm: "flex" },
        flexDirection: "column",
        alignItems: "center",
        py:3
      }}
    >
      <Box
        sx={{
          mt: 4,
          mx:3
        }}
      >
        <Typography fontFamily="Antartida" fontWeight="bold" color="#FFFFFF" fontSize={{xs:18,md:22}} textAlign="center">{"Recibe consejos y oportunidades de trabajo 100% remotas y en dólares de weKnow Inc."}</Typography>
      </Box>
      <Box
        sx={{
          my: 5,
          width: "80vw",
          display: "flex",
          justifyContent: "center"
        }}
      >
        <SubscriptionInput/>
      </Box>
    </Box>
  )
}
