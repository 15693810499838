// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
// normalize CSS across browsers
// import "./src/normalize.css"
// custom CSS styles

import "./src/code-highlight.css"

// Highlighting for code blocks
require("prismjs/themes/prism-okaidia.css")
require("prismjs/plugins/command-line/prism-command-line.css")
