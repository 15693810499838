import React from "react";
import Seo from "../components/Seo";
import { Box, Container } from '@mui/material';
import Header from "../components/Header";
import {
    createTheme,
    ThemeProvider,
    responsiveFontSizes,
} from "@mui/material/styles";
import Footer from "../components/Footer";
import "../css/privacy.scss"




const ApplyResponse = () => {

    let myTheme = createTheme({
        palette: {
            type: 'light',
            primary: {
                main: '#EA4253',
                contrastText: "#fff"
            },
            secondary: {
                main: '#f50057',
                text: "#fff"
            },
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1440,
            },
        },
    });
    myTheme = responsiveFontSizes(myTheme);

    return (
        <div>
            <Seo title="Thank you for subscribing!" />
            <ThemeProvider theme={myTheme}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100vh',
                        backgroundColor: '#fff'
                    }}
                >
                    <Header />
                    <Container maxWidth="lg" sx={{ mt: 5, display: "flex", flexDirection: "column", alignItems: "flex-start" }}  >
                        <div className="privacy-container">
                            <h3 className="privacy-title">Privacy Policy</h3>

                            <div className="divider"></div>

                            <div className="privacy-text">
                                <p>This website collects some personal data from its users.</p>

                                <h3>Feel Safe</h3>

                                <p>We respect your privacy and want to make you comfortable with the privacy policy and the security measures that we take to protect your personal data.<strong>&nbsp;</strong></p>

                                <p>We truly believe users are the only owners of their data and can request modification or deletion at any time. &nbsp;In fact, a general rule at weKnow is that we don't collect your personal information unless you chose to provide that information to us.</p>

                                <p>This Privacy Policy informs you how weKnow collects, uses, secures and shares your information. The only uses of data are those highlighted in this policy, please make sure to review all the information below.</p>

                                <h3>Types of data collected</h3>

                                <ol>
                                    <li>
                                        <p>Information that you provide when contacting us via online contact forms, emails or phone.</p>
                                    </li>
                                    <li>
                                        <p>Application information, including your CV, in case you apply for a job at weKnow.</p>
                                    </li>
                                    <li>
                                        <p>Among the types of personal data that this website collects, by itself or through third parties, there are: Cookies and Usage data.</p>
                                    </li>
                                </ol>

                                <h3>The purposes of processing</h3>

                                <ol>
                                    <li>
                                        <p>The data concerning the User is collected to allow weKnow to provide its Services, as well as for the following purposes: Analytics and Interaction with external social networks and platforms.</p>
                                    </li>
                                </ol>

                                <h3>Mode and place of processing the data</h3>

                                <ol>
                                    <li>
                                        <p>The data may be accessible to certain types of persons in charge, involved with the operation of this website (administration, sales, marketing, legal, system administration) or external parties (such as third-party technical service providers, mail carriers, hosting providers, IT companies, communications agencies) appointed, if necessary, as data Processors by weKnow.</p>
                                    </li>
                                    <li>
                                        <p>weKnow may process personal data relating to users if one of the following applies:</p>

                                        <ol>
                                            <li>
                                                <p>Users have given their consent for one or more specific purposes.</p>
                                            </li>
                                            <li>
                                                <p>The processing is necessary for the performance of a contract.</p>
                                            </li>
                                            <li>
                                                <p>The processing is necessary for compliance with a legal obligation that we are subject to.</p>
                                            </li>
                                            <li>
                                                <p>The processing is necessary for the purposes of the legitimate interests pursued by us or by a third party and such processing is not considered to be harmful towards you.</p>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>

                                <h3>Detailed information on the processing of personal data</h3>

                                <ol>
                                    <li>
                                        <p>Analytics and Advertising. Google Analytics, HubSpot, Double Click, Facebook Like button, Twitter Tweet button and social widgets, Facebook Connect, Optanon.</p>
                                    </li>
                                    <li>
                                        <p>Hosting:&nbsp;GCP, DigitalOcean, and Amazon Web Services (AWS).</p>
                                    </li>
                                    <li>
                                        <p>Others: Forms requiring contact info and Cookies.</p>
                                    </li>
                                </ol>

                                <h3>The rights of users</h3>

                                <ol>
                                    <li>
                                        <p>Withdraw consent at any time. Users have the right to withdraw consent where they have previously given their consent to the processing of their personal data.</p>
                                    </li>
                                    <li>
                                        <p>Object the&nbsp;processing of their data. Users have the right to object to the processing of their data if the processing is carried out on a legal basis other than consent.</p>
                                    </li>
                                    <li>
                                        <p>Access their data. Users have the right to learn if data is being processed by weKnow, obtain disclosure regarding certain aspects of the processing and obtain a copy of the data undergoing processing.</p>
                                    </li>
                                    <li>
                                        <p>Verify and seek rectification. Users have the right to verify the accuracy of their data and ask for it to be updated or corrected.</p>
                                    </li>
                                    <li>
                                        <p>Restrict the processing of their data. Users have the right, under certain circumstances, to restrict the processing of their data. In this case, weKnow will not process their data for any purpose other than storing it.</p>
                                    </li>
                                    <li>
                                        <p>Have their personal data deleted or otherwise removed. Users have the right, under certain circumstances, to obtain the erasure of their data from weKnow.</p>
                                    </li>
                                    <li>
                                        <p>Receive their data and have it transferred to another controller. Users have the right to receive their data in a structured, commonly used and machine readable format and, if technically feasible, to have it transmitted to another controller without any hindrance. This provision is applicable provided that the data is processed by automated means and that the processing is based on the User's consent, on a contract which the User is part of or on pre-contractual obligations thereof.</p>
                                    </li>
                                    <li>
                                        <p>Lodge a complaint. Users have the right to bring a claim before their competent data protection authority.</p>
                                    </li>
                                </ol>

                                <h3>Additional information about data collection and processing</h3>

                                <ol>
                                    <li>
                                        <p>Legal action. The user's personal data may be used for legal purposes by weKnow in Court or in the stages leading to possible legal action arising from improper use of this website or the related Services. The User declares to be aware that weKnow may be required to reveal personal data upon request of public authorities.</p>
                                    </li>
                                    <li>
                                        <p>How "Do Not Track" requests are handled. This website does not support "Do Not Track" requests. To determine whether any of the third-party services it uses honor the "Do Not Track" requests, please read their privacy policies.</p>
                                    </li>
                                    <li>
                                        <p>Changes to this privacy policy. weKnow reserves the right to make changes to this privacy policy at any time by giving notice to its Users on this page and possibly within this website and/or - as far as technically and legally feasible - sending a notice to users via any contact information available to weKnow.</p>
                                    </li>
                                </ol>

                                <h3>Keeping your data secure</h3>

                                <p>Our staff and associates are also bound by obligations of confidentiality and trained in the protection of personal data. We will take all reasonable steps to comply with the Legislation and use the appropriate technical and organizational measures necessary to safeguard your personal data.</p>

                                <h3>Links to other websites</h3>

                                <p>Where we provide links to websites of other organizations, this privacy notice does not cover how that organization processes personal information. We encourage you to read the privacy notices on the other websites you visit.</p>

                                <h3>Any questions or requests? More details concerning the collection or processing of personal data may be requested from weKnow at any time. Feel free to contact us at <a href="mailto:yes@weknowinc.com">yes@weknowinc.com</a></h3>

                                <p>&nbsp;</p>

                                <p>Version 1, October 2nd 2018</p>
                            </div>


                        </div>

                    </Container>
                    <Footer />
                </Box>

            </ThemeProvider>
        </div>
    );
};

export default ApplyResponse;