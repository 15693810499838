import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Search from "./Search/index"
import { Box } from "@mui/system"

const SearchBox = () => (
    // Graphql query used to retrieve the serialized search index.
  <StaticQuery
    query={graphql`
    query SearchIndexQuery {
      siteSearchIndex {
        index
      }
    }
  `}
    render={data => (
      <Box
      sx={{
        display:"flex",
        justifyContent:"flex-end"
      }}>
        <Search searchIndex={data.siteSearchIndex.index} />
      </Box>
    )}
  />
)

export default SearchBox