import React from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';

export default function PostTags({ tags }) {

  return (
    <Box sx={{ display: 'flex' }}>
      <Stack direction="row" spacing={1} sx={{ mr: 1 }}>
        {tags? tags.map((data) => {
          return (
            <Chip
              size="small"
              key={data}
              label={data}
              sx={{ fontFamily:"Lato" }}
            />
          );
        }): ''}
      </Stack>
    </Box>
  )
}
