import React, { useState } from "react";
import Seo from "../components/Seo";
import { Box } from '@mui/material';
import addToMailchimp from "gatsby-plugin-mailchimp"
import { navigate } from "gatsby"
import Header from "../components/Header";
import {
    createTheme,
    ThemeProvider,
    responsiveFontSizes,
} from "@mui/material/styles";
import Footer from "../components/Footer";
import "../css/mobile-subscribe.scss"

const MovileSubscribe = () => {

    let myTheme = createTheme({
        palette: {
            type: 'light',
            primary: {
                main: '#EA4253',
                contrastText: "#fff"
            },
            secondary: {
                main: '#f50057',
                text: "#fff"
            },
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1440,
            },
        },
    });
    myTheme = responsiveFontSizes(myTheme);

    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const tags = "2312534,2312718";

    let handleSubmit = async e => {
        e.preventDefault()

        addToMailchimp(email, { FNAME: name, tags: tags })
            .then(({ msg, result }) => {
                console.log("msg", `${result}: ${msg}`)

                if (result !== "success") {
                    throw msg
                }

                localStorage.setItem("subscribed", true)
                sessionStorage.setItem("displayed", true)

                navigate("/subscription-success/")
            })
            .catch(err => {
                console.log("err", err)
                alert(err)
            })
    }

    return (
        <div>
            <Seo title="Subscribe now!" />
            <ThemeProvider theme={myTheme}>
                <Header />
                <Box
                    sx={{
                        backgroundColor: "#383941",
                        py: 3
                    }}
                >
                    <form
                        className="mobile-mail-subscribe-form"
                        name={`mail-subscribe`}
                        method="POST"
                        onSubmit={handleSubmit}
                    >
                        <div className="mobile-subscribe">
                            <div className="mobile-subscribe-field">
                                <input
                                    className="mobile-subscribe-input"
                                    type="text"
                                    label="Name"
                                    onChange={e => setName(e.target.value)}
                                    placeholder="Name"
                                    name="name"
                                    id="name"
                                />
                            </div>
                            <div className="mobile-subscribe-field">
                                <input
                                    className="mobile-subscribe-input"
                                    type="email"
                                    id="email"
                                    label="Email"
                                    placeholder="Email address"
                                    required
                                    onChange={e => setEmail(e.target.value)}
                                />
                            </div>

                            <button type="submit" className="mobile-subscribeB">
                                Subscribe
                            </button>
                        </div>
                    </form>

                </Box>
                <Footer />
            </ThemeProvider>
        </div>
    );
};

export default MovileSubscribe;
