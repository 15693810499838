import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';

export default function PostInfo({ date, name, tags }) {

  return (
    <Box sx={{ display: 'flex' }}>
      <Typography variant="subtitle1" sx={{ mr: 1 }}>
        {date}
      </Typography>
      <Divider orientation="vertical" flexItem />
      <Typography variant="subtitle1" sx={{ ml: 1, flexGrow: 1 }}>
        {name}
      </Typography>
      <Stack direction="row" spacing={1} sx={{ ml: 1 }}>

        {tags? tags.map((data) => {
          return (
            <Chip
              size="small"
              key={data}
              label={data}
            />
          );
        }): ''}

      </Stack>
    </Box>
  )
}
