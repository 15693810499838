import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import ContributorCard from "../components/ContributorCard"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import PaginationNew from "../components/PaginationNew"

const GlossaryIndex = ({ data, location, pageContext }) => {
  let posts = data.allMarkdownRemark.nodes;

  if (posts.length === 0) {
    return (
      <Layout location={location} title="Contribuidores" type="contribuidores">
        <Seo title="Contribuidores" />
        <p>
          No contributors found.
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title="Contribuidores" type="contribuidores" hideSecTitle={true}>
      <Seo title="Contribuidores" />

      <Grid
        container
        alignItems="stretch"
        direction="row"
        spacing={8}
        sx={{mt:0.4}}
      >
        {posts.map(post => {
          return (
            <Grid item xs={12} sm={6} md={4} key={post.fields.slug} sx={{ display: 'flex', flexDirection: 'column' }} >
              <ContributorCard card={post} />
            </Grid>
          )
        })}
      </Grid>
      <Box my={6} display="flex" justifyContent="center">
        <PaginationNew
          count={pageContext.numPages}
          defaultPage={pageContext.currentPage}
          pagePath = "contribuidores"
        />
      </Box>
    </Layout>
  )
}

export default GlossaryIndex

export const pageQuery = graphql`
  query contributorListQuery($skip: Int!, $limit: Int!){
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: {order: ASC, fields: [frontmatter___title]}
      filter: {fileAbsolutePath: {regex: "/(/user/)/"}}
      limit: $limit
    skip: $skip
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          name
          title
          company
          company_url
          province
          country
          path
          facebook
          twitter
          linkedin
          email
          website
          drupal
          created(formatString: "DD MMM YYYY", locale: "es-MX")
          picture {
            childImageSharp {
              gatsbyImageData(
                aspectRatio: 1
                width: 600
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }
`
