import Typography from "@mui/material/Typography"
import * as React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby-theme-material-ui"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Paper from "@mui/material/Paper"
import Grid from "@mui/material/Grid"
import { StaticImage } from "gatsby-plugin-image"
import Box from "@mui/material/Box"

const AboutPage = ({ data, location }) => {

  return (
    <Layout location={location} title="Acerca de 7Sabores.com">
      <Seo title="Acerca de 7Sabores.com" />
      <Paper>

        <Grid container
          direction="row"
          justifyContent="center"
        >
          <Grid item justify="center" alignItems="center">
              <StaticImage
                src="../images/drupal-distro.png"
                alt="User"
                placeholder="blurred"
              />
          </Grid>

          <Grid item sm={12} md>
            <Box p={3}>
              <Typography variant="body1" mb={2}>
                El proyecto <strong>7Sabores</strong> nace a inicios del 2012 como una idea que pretenda dar una solución a la necesidad que surge de contar con contenido educativo de forma digital en el área tecnológica y más específico en temas tales como Drupal, Symfony, .Net y otros. <strong>7Sabores</strong> intenta dar una respuesta a esa necesidad proveyendo diversas formas de aprendizaje que abarcan varias plataformas y media.
              </Typography>
              <Typography variant="body1" mb={2}>
                Como parte de este esfuerzo y en orden de brindar una solución innovadora, es que se crea una analogía entre las “recetas” de comida con el aprendizaje de estas tecnologías; esto para intentar mejorar la forma en la cual los usuarios “digieren” el conocimiento y la retención del mismo.
              </Typography>
              <Typography variant="body1" mb={2}>
                El equipo que conforma <strong>7Sabores</strong> sabe de primera mano la necesidad que existe de contar con material que permita el autoaprendizaje en diversas áreas de la tecnología de la información. Encontramos invaluable la difusión del conocimiento por medio de nuevos métodos multimedia que lleguen a más personas y a la larga se conviertan en herramientas que generen empleo y formen profesionales.
              </Typography>
              <Typography variant="body1" mb={2}>
                Bienvenidos a nuestra mesa y más que invitados a quedarse a compartir. Esperamos les guste lo que con tanto esfuerzo cocinamos para ustedes. Nos entusiasma lo que se ha logrado pero nos emociona más lo que podemos lograr en un futuro próximo.
              </Typography>
              <Typography variant="h5" component="h2" gutterBottom>
                Contribuidores
              </Typography>
              <Typography variant="body1" mb={2}>
                El sitio 7Sabores no sería nada sin nuestros colaboradores que se esmeran por aportar contenido de calidad. Ver listado de <Link to="/contribuidores">contribuidores</Link>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
