import * as React from 'react';
import "./blog-card.scss"
import PropTypes from 'prop-types';
import PostTags from '../PostTags';
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from 'gatsby-theme-material-ui';


function BlogCardNew(props) {

    const { post } = props;
    const image = getImage(post.image)
    let coverImage

    if (image !== undefined) {
        coverImage = < GatsbyImage image={image} alt={post.title} className='post-image' />
    } else {
        coverImage = < StaticImage
            src="../../../content/assets/default-stock-image3.png"
            alt="User"
            placeholder="blurred"
            width={476}
            height={304}
        />
    }

    return (
        <div>
            <div className='blog-entry'>
                <div className='blog-entry__info'>
                    <div className="post-tags">
                        <PostTags tags={post.topics}/>
                    </div>
                    <h4>
                        {post.title}
                    </h4>
                    <div className='post-text'>
                        <span dangerouslySetInnerHTML={{
                            __html: post.description,
                        }}>
                        </span>
                        <span>
                            <Link className="post-link" to={`/${post.link}`}>Leer mas</Link>
                        </span>
                    </div>

                </div>
                <div className='blog-entry__img'>
                    {coverImage}
                </div>
            </div>
            <hr />
        </div>

    )
}

BlogCardNew.propTypes = {
    post: PropTypes.object,
};

export default BlogCardNew
