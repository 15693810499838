import * as React from 'react';
import "./hero.css"
import { StaticImage } from 'gatsby-plugin-image';

const Hero = ({ type, title, displayTitle }) => {

  let heroImage;

  switch ( type ) {
    case 'home':
      heroImage = <StaticImage src='../../images/hero_home.png' alt='Hero' />
      break;
    case 'blog':
      heroImage = <StaticImage src='../../images/hero_blog.png' alt='Hero' />
      break;
    case 'entrevistas':
      heroImage = <StaticImage src='../../images/hero_entrevistas.png' alt='Hero' />
      break;
    case 'contribuidores':
      heroImage = <StaticImage src='../../images/hero_contribuidores.png' alt='Hero' />
      break;
    case 'videos':
      heroImage = <StaticImage src='../../images/hero_videos.png' alt='Hero' />
      break;
    case 'glosario':
      heroImage = <StaticImage src='../../images/hero_glosario.png' alt='Hero' />
      break;
    default:
      return null;
  }

  if (displayTitle === true) {
  title = <h1>{title}</h1> || '';
  } else {
    title = '';
  }

  return (
    <div className="hero">
      {heroImage}
      {title}
    </div>
  );
}

export default Hero;
