import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import BlogCardNew from "../components/BlogCard/index"
import Typography from "@mui/material/Typography"
import { Button } from "gatsby-theme-material-ui"
import Box from '@mui/material/Box';
import { Divider } from "@mui/material"
import { Helmet } from "react-helmet"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes



  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} type="home" >
      <Helmet
        title="7Sabores | Blog en español para programadores Drupal, Wordpress, .Net, Javascript y DevOps."
        meta={[
          {
            name: `description`,
            content: `7Sabores es un blog para desarrolladores de software, creado por weKnow Inc. Compartimos consejos, ayuda técnica y novedades para programadores Drupal, Wordpress, .Net, Javascript y DevOps.`,
          },
          {
            name: `keywords`,
            content: `Blog para programadores, desarrolladores, código, Drupal, Wordpress, .Net, Javascript, DevOps`,
          }
        ]}
      />

      <Typography variant="h4" component="h2" m={1} p={1} sx={{ textAlign: { xs: "center", md: "left" }, fontFamily:"Antartida", fontWeight:"bold" }}>
        Entradas de Blog
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 1,
          m: 1
        }}>
        <Divider />
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug


          const postContent = {
            title: title,
            description: post.excerpt,
            image: post.frontmatter.cover,
            imageText: title,
            link: post.frontmatter.path,
            linkText: 'Ver',
            topics: post.frontmatter.topics,
            author: post.frontmatter.author,
            date: post.frontmatter.date.charAt(0).toUpperCase() + post.frontmatter.date.slice(1)
          };

          return (
            <BlogCardNew key={post.frontmatter.path} post={postContent} />
          )
        })}
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          p: 1,
          m: 1,
        }}
      >
        <Button variant="outlined" to="/blog" sx={{ px: 5, py: 1, color: "#3D3E3D", borderColor: "#CBC9C9", backgroundColor: "#F4F7F5", fontFamily: "Antartida", fontWeight: "bold", textTransform: "none" }}>
          Leer Más…
        </Button>
      </Box>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: {fields: [frontmatter___date], order: DESC}
      limit: 4
      filter: {fileAbsolutePath: {regex: "\/blog/"}}
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY", locale: "es-MX")
          title
          author
          topics
          summary
          path
          cover {
            childImageSharp {
              gatsbyImageData(
                width: 476
                height: 304
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }

  }
`
